<template>
  <div class="default-layout__wrapper">
    <div class="default-layout">
      <a-layout has-sider>
        <a-layout-sider v-model:collapsed="collapsed" :trigger="null" collapsible  theme="light">
          <left-menu :collapsed="collapsed" @update="handleCollapse()" />
        </a-layout-sider>
        <a-layout  class="default-layout__view">
          <div class="default-layout__view--content">
            <slot />
          </div>
        </a-layout>
      </a-layout>
    </div>
  </div>
</template>

<script setup>
definePageMeta({
  middleware: 'auth',
})
const collapsed = ref(false);
const handleCollapse = () => {
  collapsed.value = !collapsed.value;
};
</script>

<style lang="scss">
.default-layout {
  display: flex;
  min-height: 100vh;
  background: var(--neutral-n-2);
  position: relative;

  width: 100%;

  &__wrapper {
    display: flex;
    flex: 1;
    overflow: hidden;
    background: var(--neutral-n-2);
  }

  &__view {
    flex: 1;
    overflow: auto;
   // padding: 28px 32px 29px 24px;
    display: flex;
    flex-direction: column;
    gap: 23px;

    &--content {
      min-width: 1000px;
    }
  }

  .ant-layout {
    background: transparent;
  }

   .ant-layout-sider {
     //overflow-y: auto;
 //    height: 100vh;
     background: var(--Red-Primary, #0D52A2);
   }
}
</style>