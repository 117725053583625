<template>
  <div class="left-menu">
    <div class="left-menu__logo">
      <div @click="logoRemoveCollapsed" class="logo">
        <img src="/images/logo.png" alt="logo" />
      </div>
    </div>
    <a-menu v-model:selectedKeys="currentKey" theme="light" mode="inline">
      <template v-for="item in MENU" :key="item.key">
        <a-menu-item
          v-if="!item.subMenu"
          :key="item.key"
          @click="
            () => {
              if (item.subMenu) {
                return
              }
              goToPage(item.path)
            }
          "
        >
          <div
            :class="`flex-20 ${currentKey.includes(item.key) && 'active'}`"
            v-show="!collapsed"
          >
            <span
              :class="`content ${currentKey.includes(item.key) && 'active'}`"
            >
              <component
                :is="{ ...item.icon }"
                :color="currentKey.includes(item.key) ? '#262626' : '#FAFAFA'"
              />
            </span>
            <p>{{ item.label }}</p>
          </div>
          <div
            class="flex-20"
            :style="{ 'justify-content': 'center' }"
            v-show="collapsed"
          >
            <a-popover
              placement="right"
              color="#E5F2FF"
              overlayClassName="popover-left-menu"
            >
              <template #content>
                <span>{{ item.label }}</span>
              </template>
              <span
                :class="` content ${currentKey.includes(item.key) && 'active'}`"
              >
                <component
                  :is="{ ...item.icon }"
                  :color="currentKey.includes(item.key) ? '#262626' : '#FAFAFA'"
                />
              </span>
            </a-popover>
          </div>
        </a-menu-item>
        <a-sub-menu
          v-if="item.subMenu"
          :key="item.key"
          :class="
            item.subMenu?.findIndex((i) => i.key === currentKey[0]) > -1
              ? 'sub-open'
              : ''
          "
        >
          <template v-slot:title>
            <span class="content">
              <component
                :is="{ ...item.icon }"
                :color="
                  item.subMenu?.findIndex((i) => i.key === currentKey[0]) > -1
                    ? '#FAFAFA'
                    : '#262626'
                "
              />
            </span>
          </template>

          <a-menu-item
            v-for="subItem in item.subMenu"
            :key="subItem.key"
            @click="goToPage(subItem.path)"
          >
          </a-menu-item>
        </a-sub-menu>
      </template>
    </a-menu>
    <div class="logout">
      <a-button type="link" @click="toggleTrigger">
        <svg
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 19L8 12L15 5"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </a-button>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useAuthStore } from '~/stores/useAuthStore'
import RebateIcon from '../element/left-menu-icons/RebateIcon.vue'
import ClinicIcon from '../element/left-menu-icons/ClinicIcon.vue'
import SettingsIcon from '../element/left-menu-icons/SettingsIcon.vue'
import DoctorIcon from '../element/left-menu-icons/DoctorIcon.vue'
import ReportIcon from '../element/left-menu-icons/ReportIcon.vue'
import EmailIcon from '../element/left-menu-icons/EmailIcon.vue'
// store
const authStore = useAuthStore()
const { logout } = authStore
const authUser = computed(() => authStore.authUser)

//props
const props = defineProps({
  collapsed: {
    type: Boolean,
    default: false,
  },
})
//emit
const emit = defineEmits(['update'])

const toggleTrigger = () => {
  const toggleElement = document.getElementsByClassName('logout')[0]
  if (!props.collapsed) {
    toggleElement.classList.add('is-collapsed')
    toggleElement.classList.remove('is-not-collapsed')
  } else {
    toggleElement.classList.remove('is-collapsed')
    toggleElement.classList.add('is-not-collapsed')
  }
  emit('update')
}

const logoRemoveCollapsed = () => {
  const toggleElement = document.getElementsByClassName('logout')[0]
  toggleElement.classList.remove('is-collapsed')
  toggleElement.classList.add('is-not-collapsed')
  emit('update')
}

// varible
const defineMenu = [
  {
    key: 'rebate',
    icon: markRaw(RebateIcon),
    label: 'Monthly Rebates',
    path: '/rebate',
    list: ['rebate', 'rebate-id'],
    role: ['hoc', 'hoi', 'finance', 'superadmin', 'doi'],
  },
  {
    key: 'payee',
    icon: markRaw(ClinicIcon),
    label: 'Payees',
    path: '/payee',
    list: ['payee', 'payee-id'],
    role: ['finance', 'superadmin'],
  },
  {
    key: 'report',
    icon: markRaw(ReportIcon),
    label: 'DSFs',
    path: '/report',
    list: ['report', 'report-id'],
    role: ['finance', 'superadmin'],
  },
]

// router
const router = useRouter()

// data
const currentKey = ref([])
const currentSubKey = ref([])
const MENU = ref([...defineMenu])

// methods
const setRoute = (route) => {
  let correctPath = route.name
  const findItem = [...MENU.value].find((o) => {
    if (o.list.includes(correctPath)) {
      return true
    }
    let find = false
    if (o.subMenu) {
      find = o.subMenu.findIndex((i) => i.list.includes(correctPath)) > -1
    }
    return find
  })
  if (findItem) {
    currentKey.value = [findItem.key]
    currentSubKey.value = []
    if (findItem.subMenu) {
      currentSubKey.value = [findItem.key]
      currentKey.value = [
        findItem.subMenu.find((i) => i.list.includes(correctPath)).key,
      ]
    }
    const isHasAccess = findItem.role.includes(authUser.value.role)
    console.log('isHasAccess', isHasAccess)
    if (!isHasAccess) {
      router.push('/404?route=' + route.fullPath)
    }
  } else {
    currentKey.value = []
    currentSubKey.value = []
  }
}

watch(
  () => router.currentRoute.value,
  () => {
    setRoute(router.currentRoute.value)
  },
  { immediate: true, deep: true }
)

watch(
  () => authUser.value,
  (val) => {
    const { role } = val || {}
    if (role === 'hoc' || role === 'hoi' || role === 'doi') {
      MENU.value = [
        {
          key: 'rebate',
          icon: markRaw(RebateIcon),
          label: 'Rebate List',
          path: '/rebate',
          list: ['rebate', 'rebate-id'],
          role: ['hoc', 'hoi', 'finance', 'superadmin', 'doi'],
        },
        {
          key: 'payee',
          icon: markRaw(ClinicIcon),
          label: 'Payees',
          path: '/payee',
          list: ['payee', 'payee-id'],
          role: ['finance', 'superadmin', 'hoc', 'hoi', 'doi'],
        },
      ]
    }
    if (role === 'superadmin') {
      MENU.value = [
        ...defineMenu,
        {
          key: 'email-templates',
          icon: markRaw(EmailIcon),
          label: 'Email Templates',
          path: '/email-templates',
          list: ['email-templates', 'email-templates-id'],
          role: ['superadmin'],
        },
        {
          key: 'admin',
          icon: markRaw(DoctorIcon),
          label: 'Admins',
          path: '/admin',
          list: ['admin', 'admin-id'],
          role: ['superadmin'],
        },
      ]
    }
  },
  { immediate: true, deep: true }
)

const goToPage = (path) => {
  router.push(path)
}
</script>

<style lang="scss">
.left-menu {
  background: var(--primary-p);
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  position: relative;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .logo {
      display: flex;
      height: 48px;
      width: 48px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      background: #fff;
      border-radius: 50%;
      padding: 4px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .ant-menu {
    background: transparent !important;
    border-inline-end: 0px !important;
    padding: 0;

    .ant-menu-item + .ant-menu-item {
      margin-top: 32px !important;
    }

    .ant-menu-submenu + .ant-menu-item {
      margin-top: 32px !important;
    }

    .ant-menu-item + .ant-menu-submenu {
      margin-top: 32px !important;
    }

    .ant-menu-submenu-title {
      margin: 0 !important;
      width: 100% !important;
      height: 48px;
      padding: 0;
      border-radius: 12px;
    }

    .sub-open {
      //&.ant-menu-submenu-open {
      .ant-menu-submenu-arrow {
        color: var(--neutral-n-2) !important;
      }

      .ant-menu-submenu-title {
        background: var(--primary-p);

        .content {
          color: var(--neutral-n-2);
        }
      }
    }

    .ant-menu-sub {
      background: transparent !important;

      .ant-menu-item + .ant-menu-item {
        margin-top: 4px !important;
      }

      .ant-menu-item {
        .ant-menu-title-content {
          color: #8c8c8c !important;

          .ant-popover-open {
            display: none !important;
          }
        }

        &.ant-menu-item-selected {
          background: transparent !important;

          .ant-menu-title-content {
            color: #1f1f1f !important;
          }
        }
      }
    }

    .ant-menu-item {
      margin: 0 !important;
      width: 100% !important;
      height: 48px;
      padding: 0px;
      border-radius: 12px;
      padding: 0 !important;

      &.ant-menu-item-selected {
        .ant-menu-title-content {
          color: var(--neutral-n-2);
          /* Body / Body 2 - 13px - R - 0.25px */
          font-family: 'Urbanist', sans-serif;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
          /* 169.231% */
          letter-spacing: 0.25px;
        }
      }
    }

    .ant-menu-title-content {
      display: flex;
      justify-content: center;
      color: var(--neutral-n-10);
      /* Body / Body 2 - 13px - R - 0.25px */
      font-family: 'Urbanist', sans-serif;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      /* 169.231% */
      letter-spacing: 0.25px;

      .content {
        display: flex;
        align-items: center;
        width: 48px;
        height: 48px;
        padding: 12px;
        transition: all 0.3s ease;

        &.active {
          border-radius: 1000px;
          background: var(--Shorten-Secondary-S4, #e5f2ff);
        }

        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: var(--neutral-n-4);
  }

  .logout {
    position: absolute;
    top: 18px;
    width: calc(100%);
    display: flex;
    justify-content: right;
    align-items: center;
    transition: all 0.2s ease;

    .ant-btn {
      height: 32px;
      width: 32px;
      margin-right: -10px;
      margin-top: 30px;
      border-radius: 50px;
      background: #0086d6;
      // box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      // gap: 16px;
      padding: 0 !important;
      // z-index: 9999;//

      span {
        color: var(--orange);
        /* Caption/Caption - 12px - R - 0.4px */
        font-family: 'Urbanist', sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        /* 166.667% */
        letter-spacing: 0.25px;
      }
    }
  }

  .is-collapsed {
    opacity: 0;
    display: none;
  }

  .is-not-collapsed {
    opacity: 1;
    display: flex;
  }

  .flex-20 {
    display: flex;
    gap: 12px;
    width: 100%;
    align-items: center;
    transition: all 1s ease-in-out;

    p {
      color: var(--Neutral-N11, #fff);
      font-family: var(--primary-font-family);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      /* 150% */
      letter-spacing: -0.05px;
    }

    &.active {
      background: var(--Shorten-Secondary-S4, #e5f2ff);
      border-radius: 12px;

      p {
        color: var(--Neutral-N11, #1f1f1f);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.ant-tooltip {
  display: none !important;
}

.popover-left-menu {
  .ant-popover-arrow {
    display: none;
  }

  .ant-popover-inner {
    padding: 8px;
    color: var(--Neutral-N9, #434343);
    /* Urbanist/Sub/Subtitle 2 - 14px - SB- 0.1px */
    font-family: 'Urbanist', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    /* 171.429% */
    letter-spacing: -0.15px;
    border-radius: 4px;
    padding: 8px 12px;
  }
}
</style>
